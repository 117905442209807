import { useProfileData } from '../AuthProvider'
import DateRangeInput from '../DateRangeInput'
import Modal from '../Modal'
import Multiselect from '../Multiselect'
import Popover from '../Popover'
import Select from '../Select'
import Template from '../Template'
import * as list from './list.js'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover'

function RequestReservation(props) {
  const logIn = useDialogState({ baseId: 'logIn' })
  const waitlist = useDialogState({ baseId: 'waitlist' })

  return (
    <>
      <DialogDisclosure className={style.buttonSubmit} {...waitlist}>
        {props.waitingListCopy}
      </DialogDisclosure>

      {/* The reservation modal */}
      {/* <Modal>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Request stay</h2>
              <button
                className={style.buttonClose}
                onClick={() => waitlist.hide()}
              >
                Close
              </button>
            </div>
            <form
              onSubmit={(event) => {
                event.preventDefault()
                const profileData = {
                  headline: event.target.headline.value,
                }

                console.log(
                  'TODO logo new profileData from',
                  event.target,
                  profileData
                )
              }}
            >
              <div className={style.block}>
                <h5>
                  <strong>Select the dogs that need a sitter</strong>
                </h5>
                <div className={style.inputGroupContainer}>
                  <div className={style.inputGroup}>
                    <input
                      type='checkbox'
                      id='dog1'
                      name='dog'
                      value='Bossy'
                    ></input>
                    <label htmlFor='dog1'>Bossy</label>
                  </div>
                  <div className={style.inputGroup}>
                    <input
                      type='checkbox'
                      id='dog2'
                      name='dog'
                      value='Aurelieus'
                    ></input>
                    <label htmlFor='dog2'>Aurelieus</label>
                  </div>
                </div>

                <div className={style.details}>
                  <h5>Reservation details</h5>
                  <div className={style.dates}>
                    <div>
                      <label>Drop-off date</label>
                      <input type='date' />
                    </div>
                    <div>
                      <label>Pick-up date</label>
                      <input type='date' />
                    </div>
                  </div>
                  <div className={style.textarea}>
                    <label>Your message</label>
                    <textarea
                      required
                      rows='4'
                      placeholder="This is a good place to introduce yourself and your dog, and give a few details about why you're looking for a sitter.  At least 200 words are recommended."
                    />
                  </div>
                </div>

                <div>
                  <Link className={style.button} to={'/message'}>
                    Send a message with your request
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal> */}

      {/* show the waitlist modal */}
      <Modal {...waitlist}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Join the list</h2>
              <button
                className={style.buttonClose}
                onClick={() => waitlist.hide()}
              >
                Close
              </button>
            </div>
            <p>
              This business isn't currently accepting reservations. Join our
              waitlist and we'll let you know when they are!
            </p>
            <form
              action='https://app.loops.so/api/newsletter-form/clmusgt0c0185l40oqj3wbd3s'
              method='post'
              onSubmit={async (event) => {
                event.preventDefault()
                const response = await fetch(event.target.action, {
                  method: event.target.method,
                  body: new URLSearchParams(new FormData(event.target)),
                })

                const result = await response.json()

                if (result.success) {
                  navigate('/thank-you-for-signing-up')
                  return
                }

                alert(result)
              }}
            >
              <div className={style.form}>
                <label htmlFor={'email'}>Email address</label>
                <input type='email' name='email' required />
                <input type='hidden' name='userGroup' value='Waitlist signup' />
              </div>
              <button className={style.buttonSubmit} type='submit'>
                Sign up for the waitlist
              </button>
            </form>
            <p>
              Already have an account?{' '}
              <DialogDisclosure className={style.link} {...logIn}>
                Login
              </DialogDisclosure>
            </p>
          </div>
          <p className={style.note}>
            By signing up, you agree to receive emails from us. We'll only send
            you emails related to doggos.com and your account.
          </p>
        </div>
      </Modal>

      {/* Otherwise, prompt them to login */}
      <Modal {...logIn}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Login</h2>
              <button
                className={style.buttonClose}
                onClick={() => logIn.hide()}
              >
                Close
              </button>
            </div>
            <p>
              If you already have an account, enter your email address to get a
              login link sent to your inbox.
            </p>
            <form
              onSubmit={async (event) => {
                event.preventDefault()

                // await fetch('/api/login', {
                //   method: 'post',
                //   body: JSON.stringify({ email: event.target.email.value }),
                // })

                alert(
                  "Sorry, we couldn't find an account with that email address."
                )
              }}
            >
              <label htmlFor='email'>Email address</label>
              <input
                name='email'
                type='email'
                placeholder='Your email address'
              />
              <button className={style.buttonSubmit}>Login</button>
            </form>
            <p>
              Don't have an account?{' '}
              <DialogDisclosure className={style.link} {...waitlist}>
                Sign up for the waitlist
              </DialogDisclosure>
            </p>
          </div>
        </div>
      </Modal>
    </>
  )
}

function EditProfile() {
  const editProfile = useDialogState({ baseId: 'editProfile' })
  const loadProfile = { id: 'amccloud', headline: 'This is my headline' }
  const profile = useProfileData({ optional: true })

  return (
    <span>
      {profile && (
        <DialogDisclosure className={style.editLink} {...editProfile}>
          Edit profile
        </DialogDisclosure>
      )}
      <Modal {...editProfile}>
        <div className={style.modal}>
          <div>
            <div className={style.title}>
              <h2>Edit profile</h2>
              <button
                className={style.buttonClose}
                onClick={() => editProfile.hide()}
              >
                Close
              </button>
            </div>
            <p className={style.modalCopy}>
              Your profile is where dog owners learn about you and the services
              you offer.
            </p>
            <form method='post'>
              <div className={style.block}>
                <div className={style.profileHeadline}>
                  <label>
                    Profile headline <span className={style.required}>*</span>
                  </label>
                  <input
                    type='text'
                    name='headline'
                    placeholder='Use a business name or memorable headline'
                    defaultValue={loadProfile.headline}
                    required
                  ></input>
                </div>
                <div className={style.featuredImagesSection}>
                  <label htmlFor='featured_image_1'>
                    Featured images <span className={style.required}>*</span>
                  </label>
                  <p className={style.modalCopy}>
                    Upload up to 5 images that showcase your home, pets, and
                    services
                  </p>
                  <div className={style.featuredImages}>
                    <div className={style.featuredImage}>
                      <input name='featured_image_1' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_2' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_3' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_4' type='file' />
                    </div>
                    <div className={style.featuredImage}>
                      <input name='featured_image_5' type='file' />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={style.textarea}>
                    <label htmlFor='neighborhood'>
                      Describe your neighborhood or your routine
                      <span className={style.required}> *</span>
                    </label>
                    <textarea
                      name='neighborhood'
                      rows='5'
                      placeholder="For example, 'My neighborhood has a huge park and I go walking everyday. There are 3 veterinary offices within 5 miles of my home. I go hiking every weekend. etc.'"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className={style.block}>
                <h3>About your stay</h3>
                <p className={style.modalCopy}>
                  The more details you provide, the more likely dog owners will
                  find you in search results.
                </p>
                <div className={style.optionList}>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='How often is someone home?'
                        placeholder='Choose one'
                        list={list.scheduleEstimate}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='Details about your accomodation'
                        optional='(Recommended)'
                        list={list.homeCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='How many dogs will you host at once?'
                        list={list.dogCount}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='What kinds of dogs will you host?'
                        list={list.dogCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='How much exercise can you provide?'
                        list={list.exerciseEstimate}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='Exercise details'
                        optional='(Optional)'
                        list={list.exerciseCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='Do you have resident pets?'
                        list={list.residentPetCount}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='Resident pet details'
                        optional='(Select all that apply)'
                        list={list.residentPetCriteria}
                      />
                    </div>
                  </div>
                  <div className={style.optionGroup}>
                    <div className={style.option}>
                      <Select
                        label='Can you give medication?'
                        list={list.medication}
                      />
                    </div>
                    <div className={style.option}>
                      <Multiselect
                        label='What kind?'
                        optional='(Select all that apply)'
                        list={list.medicationCriteria}
                      />
                    </div>
                  </div>
                </div>
                <div className={style.block}>
                  <h3>About you</h3>
                  <div className={style.aboutYou}>
                    <div className={style.iconUpload}>
                      <span>Upload a photo of yourself</span>
                      <input type='file'></input>
                    </div>
                    <div className={style.aboutYouCopy}>
                      <div className={style.inputContainer}>
                        <label>
                          Your first name, as you'd like it to appear on your
                          profile
                        </label>
                        <input
                          type='text'
                          placeholder="For example 'Theresea', or 'James'"
                        ></input>
                      </div>

                      <div className={style.textarea}>
                        <label>
                          Describe yourself and your experience with dogs
                        </label>
                        <textarea
                          required
                          rows='5'
                          placeholder='This is a good place to talk about yourself and your accomodation, as well as your background or unique experience you have with dogs.'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.buttonContainer}>
                  <button
                    className={style.buttonClose}
                    onClick={() => editProfile.hide()}
                  >
                    Close
                  </button>
                  <button
                    className={style.buttonSubmit}
                    onClick={() => editProfile.hide()}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </span>
  )
}

// function calculateRelationship(categories, targetCategories) {
//   let score = 0

//   targetCategories.forEach((targetCategory) => {
//     let count = categories.filter(
//       (category) => category === targetCategory
//     ).length
//     score += count
//   })

//   return score
// }

const PageListing = (props) => {
  const [bookedServices, setBookedServices] = useState(null)
  const profile = useProfileData({ optional: true })
  const platformFee = usePopoverState()

  useEffect(() => {
    const getbookedServices = () => {
      if (typeof window !== 'undefined') {
        const services = window.localStorage.getItem('services') || '{}'
        const parsedServices = JSON.parse(services)
        for (const [key, value] of Object.entries(parsedServices)) {
          if (key === '/' + window.location.pathname.split('/')[1]) {
            return value
          }
        }
        return null
      }
      return null
    }

    const bookedServicesData = getbookedServices()
    setBookedServices(bookedServicesData)
  }, [])

  const listing = props.data.listing
  const siteTitle = get(props, 'data.site.siteMetadata.title')

  return (
    <Template>
      <Helmet>
        <title>{`${listing.frontmatter.meta.title} - ${siteTitle}`}</title>
        <meta property='og:title' content={listing.frontmatter.meta.title} />
        <meta
          property='og:description'
          content={listing.frontmatter.meta.description}
        />
        {listing.frontmatter.image && (
          <meta property='og:image' content={listing.frontmatter.meta.image} />
        )}
        {listing.frontmatter.image && (
          <meta
            property='og:image'
            content={
              listing.frontmatter.image.childImageSharp.gatsbyImageData?.src
            }
          />
        )}
      </Helmet>
      <div className={style.container}>
        <div className={style.coverPhoto}>
          {listing.frontmatter.image && (
            <GatsbyImage
              className={style.image}
              alt='Image'
              image={getImage(listing.frontmatter.image)}
            />
          )}

          {listing.frontmatter.image2 && (
            <GatsbyImage
              alt='Image'
              className={style.image}
              image={getImage(listing.frontmatter.image2)}
            />
          )}

          {listing.frontmatter.image3 && (
            <GatsbyImage
              alt='Image'
              className={style.image}
              image={getImage(listing.frontmatter.image3)}
            />
          )}

          {listing.frontmatter.image4 && (
            <GatsbyImage
              alt='Image'
              className={style.image}
              image={getImage(listing.frontmatter.image4)}
            />
          )}

          {listing.frontmatter.image5 && (
            <GatsbyImage
              alt='Image'
              className={style.image}
              image={getImage(listing.frontmatter.image5)}
            />
          )}
        </div>

        {/* Logo, business name, category */}
        <div className={style.twoColumnPage}>
          <div className={style.body}>
            <div className={style.titleContainer}>
              {/* <EditProfile /> */}
              <div className={style.title}>
                {listing.frontmatter.postTitle && (
                  <h1 className={style.h1}>{listing.frontmatter.postTitle}</h1>
                )}
                <div className={style.tagContainer}>
                  <div className={style.accomodationType}>
                    {listing.frontmatter.profileData.accomodationType}
                  </div>

                  {listing.frontmatter.tags &&
                    listing.frontmatter.tags.includes('Licensed') && (
                      <span
                        key='licensed'
                        className={`${style.accomodationType} ${style.licensed}`}
                      >
                        Licensed
                      </span>
                    )}

                  {listing.frontmatter.state && (
                    <div className={style.location}>
                      <span>
                        {listing.frontmatter.city}, {listing.frontmatter.state}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {listing.body && (
              <div className={style.introductionContainer}>
                <MDXRenderer>{listing.body}</MDXRenderer>
              </div>
            )}
            <div className={style.thingsToKnow}>
              <h3>About this stay</h3>
              <div className={style.thingsToKnowContainer}>
                <div>
                  <ul>
                    {listing.frontmatter.profileData.thingsToKnow.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {listing.frontmatter.profileData.neighborhoodDescription && (
              <div className={style.neighborhood}>
                <h3>Neighborhood</h3>
                <div className={style.neighborhoodContainer}>
                  <div className={style.map}>
                    <img
                      src={`https://maps.geoapify.com/v1/staticmap?style=osm-carto&width=500&height=600&center=lonlat:${listing.frontmatter._geoloc.lng},${listing.frontmatter._geoloc.lat}&zoom=14&scaleFactor=2&apiKey=cd84c6383d8449f4bc7389926b627c6c`}
                      alt='Map'
                    ></img>
                  </div>
                  <div className={style.neighborhoodCopy}>
                    <div>
                      <h4>{listing.frontmatter.profileData.neighborhood}</h4>
                      <p>
                        {
                          listing.frontmatter.profileData
                            .neighborhoodDescription
                        }
                      </p>
                    </div>
                    {/* <div className={style.callout}>
              <h5>What is the address of this stay?</h5>
              <p>
                A complete street address will be provided after booking your
                first stay here.
              </p>
            </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={style.sidebar}>
            {/* Booking container */}
            {profile && (
              <form className={style.bookingContainer}>
                <div className={style.inputGroup}>
                  <h3>Start request</h3>
                  <div className={style.flexCol}>
                    <label className={style.label} for='date_range_input'>
                      Drop-off and pick-up dates
                    </label>
                    <DateRangeInput />
                  </div>
                  <div className={style.flexCol}>
                    <div>
                      <Multiselect
                        label='Add dogs'
                        placeholder='Select'
                        list={['Gotti', 'Capone']}
                      />
                    </div>
                  </div>
                  <div className={style.totals}>
                    <div className={style.lineItem}>
                      <div>$42 x 8 nights </div>
                      <div>$480 </div>
                    </div>
                    <div className={style.lineItem}>
                      <div>
                        <PopoverDisclosure
                          className={style.platformFee}
                          {...platformFee}
                        >
                          Platform fee
                        </PopoverDisclosure>
                        <Popover
                          {...platformFee}
                          aria-label='What is this?'
                          tabIndex={0}
                        >
                          <div className={style.popoverContent}>
                            <div className={style.fees}>
                              <div>
                                <span>Credit card processing</span>
                                <span>3.5%</span>
                              </div>
                              <div>
                                <span>Platform fee</span>
                                <span>5%</span>
                              </div>
                            </div>
                            <div>
                              <span>
                                This covers credit card processing fees and the
                                cost of offering services like refunds and
                                customer support.
                              </span>
                            </div>
                          </div>
                        </Popover>
                      </div>
                      <div>$24 </div>
                    </div>
                    <div className={`${style.lineItem} ${style.total}`}>
                      <div>Total</div>
                      <div>$504</div>
                    </div>
                  </div>
                </div>

                <div>
                  <Link
                    to={
                      '/message/?c=doggoscom-inc&m=ewogICJzdGFydERhdGUiOiAxNzIyNTUxNjIyODg0LAogICJlbmREYXRlIjogMTcyMjU1MTYyMjk4Mgp9'
                    }
                    className={style.button}
                  >
                    Start reservation request
                  </Link>
                  <p className={style.note}>
                    This reservation isn't final until it is accepted and
                    confirmed. You won't be charged yet.
                  </p>
                </div>
              </form>
            )}

            {/* Services */}
            <div className={style.services}>
              <div className={style.servicesContent}>
                <h4 className={style.servicesLabel}>Services</h4>
                <ul>
                  <li className={style.service}>
                    <div>
                      <strong className={style.title}>Boarding</strong>
                      <p>Overnight care</p>
                    </div>
                    <div>
                      <p>
                        from <strong>$42</strong>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* Chat with the sitter */}
            <div className={style.messageSitter}>
              <h5 className={style.messageSitterLabel}>Message this sitter</h5>
              <div className={style.messageSitterContent}>
                <div className={style.messageSitterTitle}>
                  <div className={style.icon}>
                    {listing.frontmatter.icon && (
                      <GatsbyImage
                        alt='Icon'
                        image={getImage(listing.frontmatter.icon)}
                      />
                    )}
                  </div>
                  <div>
                    <h5>{listing.frontmatter.postTitle}</h5>
                  </div>
                </div>
                <div className={style.requestReservation}>
                  <RequestReservation waitingListCopy={'Message'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default PageListing
