// extracted by mini-css-extract-plugin
export var accomodationType = "style-module--accomodationType--3ee16";
export var body = "style-module--body--add10";
export var bookingContainer = "style-module--bookingContainer--fb5e4";
export var button = "style-module--button--32801";
export var buttonClose = "style-module--buttonClose--0c409";
export var buttonContainer = "style-module--buttonContainer--2e792";
export var buttonSearch = "style-module--buttonSearch--cb8ad";
export var buttonSubmit = "style-module--buttonSubmit--b290b";
export var callout = "style-module--callout--7a7ee";
export var container = "style-module--container--e1416";
export var copyContainer = "style-module--copyContainer--cf57c";
export var coverPhoto = "style-module--coverPhoto--61c92";
export var editLink = "style-module--editLink--59578";
export var featuredImage = "style-module--featuredImage--bab3a";
export var featuredImages = "style-module--featuredImages--ebd66";
export var featuredImagesSection = "style-module--featuredImagesSection--8be56";
export var fees = "style-module--fees--0c1dd";
export var flexCol = "style-module--flexCol--94277";
export var form = "style-module--form--17489";
export var h1 = "style-module--h1--d0c49";
export var icon = "style-module--icon--e8dc4";
export var image = "style-module--image--71808";
export var input = "style-module--input--3e8ab";
export var introductionContainer = "style-module--introductionContainer--85301";
export var label = "style-module--label--f88d7";
export var licensed = "style-module--licensed--b7eaa";
export var lineItem = "style-module--lineItem--dceac";
export var link = "style-module--link--51e32";
export var listingIcon = "style-module--listingIcon--98d6f";
export var location = "style-module--location--6c835";
export var map = "style-module--map--6b5b2";
export var messageSitter = "style-module--messageSitter--bacc2";
export var messageSitterContent = "style-module--messageSitterContent--00bff";
export var messageSitterTitle = "style-module--messageSitterTitle--e9331";
export var modal = "style-module--modal--4cd11";
export var neighborhood = "style-module--neighborhood--af6b5";
export var neighborhoodContainer = "style-module--neighborhoodContainer--271d4";
export var neighborhoodCopy = "style-module--neighborhoodCopy--e3d8a";
export var note = "style-module--note--b717e";
export var paid = "style-module--paid--4f0b4";
export var platformFee = "style-module--platformFee--1de86";
export var popoverContent = "style-module--popoverContent--2baa8";
export var requestReservation = "style-module--requestReservation--af15b";
export var reservationHistory = "style-module--reservationHistory--253f0";
export var reservationHistoryContainer = "style-module--reservationHistoryContainer--6a7a5";
export var reservationHistoryItem = "style-module--reservationHistoryItem--51081";
export var service = "style-module--service--07756";
export var services = "style-module--services--aaf36";
export var servicesContent = "style-module--servicesContent--1d3bc";
export var servicesLabel = "style-module--servicesLabel--a86ca";
export var sidebar = "style-module--sidebar--cd952";
export var status = "style-module--status--7606d";
export var statuses = "style-module--statuses--24ff2";
export var tagContainer = "style-module--tagContainer--70632";
export var thingsToKnow = "style-module--thingsToKnow--acb94";
export var thingsToKnowContainer = "style-module--thingsToKnowContainer--19f48";
export var timeline = "style-module--timeline--d16bf";
export var title = "style-module--title--739af";
export var titleContainer = "style-module--titleContainer--bc772";
export var total = "style-module--total--af3aa";
export var totals = "style-module--totals--cdcc0";
export var twoColumnPage = "style-module--twoColumnPage--c223d";